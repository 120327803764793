<template>
  <mdb-container>
    <adminheader></adminheader>
    <section class="mt-3">
      <mdb-row>
        <mdb-col md="12">
          <h2>Liste des webinaires</h2>
          <mdb-tbl striped>
            <mdb-tbl-body>
              <tr v-for="webinaire in webinaires" :key="webinaire.ident">
                <td><mdb-icon icon="microphone-alt" v-if="webinaire.onair" alt="ON AIR!" /> {{ webinaire.name }}<div v-if="!webinaire.ispublic"><strong>mode TEST</strong></div></td>
                <td>{{ webinaire.eventdate }}</td>
                <td>
                  <mdb-dropdown>
                    <mdb-dropdown-toggle color="ins" slot="toggle" size="sm" tag="a" style="width:110%;" >Action</mdb-dropdown-toggle>
                    <mdb-dropdown-menu color="ins">
                      <mdb-dropdown-item v-on:click="gotostatswebinar(webinaire.ident)">Statistiques / infos</mdb-dropdown-item>
                      <mdb-dropdown-item v-on:click="gotowebsite(webinaire.permalink)">Voir sur le site</mdb-dropdown-item>
                      <mdb-dropdown-item v-on:click="edit(webinaire.ident)">Modifier</mdb-dropdown-item>
                      <div class="dropdown-divider"></div>
                      <mdb-dropdown-item v-on:click="askdelete(webinaire.ident, webinaire)"><strong>Supprimer</strong></mdb-dropdown-item>
                    </mdb-dropdown-menu>
                  </mdb-dropdown>
                </td>
              </tr>
            </mdb-tbl-body>
          </mdb-tbl>
          <mdb-btn v-on:click="add()">Ajouter un Webinaire</mdb-btn>
        </mdb-col>
      </mdb-row>
    </section>
    <mdb-modal :show="settodelete != false" @close="settodelete = false" danger>
      <mdb-modal-header>
        <p class="heading">Supression d'un webinaire</p>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="3">
            <p class="text-center mt-3"><mdb-icon icon="exclamation" size="4x"/></p>
          </mdb-col>
          <mdb-col col="9" class="pt-3">
            <p>Êtes-vous certain de vouloir supprimer le webinaire <strong>{{ currentwebinaire.name }} {{ currentwebinaire.eventdate }}</strong> ?</p>
            <p v-if="!currentwebinaire.ispast">Un courriel sera envoyé à toutes les personnes s'ayant déjà inscrit à ce webinaire.<br /> <strong>({{ currentwebinaire.nbregistrations }} inscription<div v-if="currentwebinaire.nbregistrations > 1">s</div>)</strong></p>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn tag="a" color="danger" v-on:click="confirmdelete()">Supprimer</mdb-btn>
        <mdb-btn tag="a" outline="danger" @click="settodelete = false">Annuler</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </mdb-container>
</template>

<script>
    import Vue from 'vue';
    import Adminheader from '@/components/Adminheader.vue';

    import {
        mdbContainer,
        mdbRow,
        mdbCol,
        mdbTbl,
        mdbTblBody,
        mdbBtn,
        mdbModal,
        mdbModalHeader,
        mdbModalBody,
        mdbModalFooter,
        mdbIcon,
        mdbDropdown,
        mdbDropdownToggle,
        mdbDropdownMenu,
        mdbDropdownItem,
    } from 'mdbvue';

    import Auth from '../libs/Auth';
    import Apicall from '../libs/Apicall';

    export default Vue.extend({
        name: 'webinairelistadmin',
        components: {
            Adminheader,
            mdbContainer,
            mdbRow,
            mdbCol,
            mdbTbl,
            mdbTblBody,
            mdbBtn,
            mdbModal,
            mdbModalHeader,
            mdbModalBody,
            mdbModalFooter,
            mdbIcon,
            mdbDropdown,
            mdbDropdownToggle,
            mdbDropdownMenu,
            mdbDropdownItem,
        },
        data() {
            return {
                webinaires: [],
                settodelete: false,
                currentwebinaire: [],
            };
        },
        mounted() {
            Auth.validateIfCanAccess('ADMINISTRATOR', 'Dashboard');
            this.getAndDisplayWebinaires();
        },
        methods: {
            getAndDisplayWebinaires() {
                const self = this;
                const api = new Apicall();
                api.getAllWebinairesAdmin().then((response) => {
                    if (response.status === 'OK') {
                        self.webinaires = response.data.webinars;
                    } else {
                        console.log('Erreur!!!');
                    }
                });
            },
            edit(_ident) {
                this.$router.push({ name: 'Viewwebinaire', params: { ident: _ident } });
            },
            add() {
                this.$router.push({ name: 'Addwebinaire' });
            },
            askdelete(_ident, _infos) {
                this.currentwebinaire = _infos;
                this.settodelete = _ident;
                console.log(_infos);
            },
            confirmdelete() {
                const self = this;
                const api = new Apicall();
                api.deleteWebinaire(this.settodelete).then(() => {
                    this.settodelete = false;
                    self.getAndDisplayWebinaires();
                });
            },
            gotostatswebinar(_ident) {
                this.$router.push({ name: 'Statswebinaire', params: { ident: _ident } });
            },
            gotowebsite(permalink) {
                window.location.href = `/webinaire/${permalink}`;
            },
        },
    });
</script>
